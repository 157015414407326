<template>
    <div class="explorer">
        <div class="flex items-center justify-between">
            <div class="folderNameBreadcrumb">
                <!-- <h4 class="font-medium">Client Input</h4> -->
                <div class="flex items-center flex-wrap">
                    <div @click="onBreadcrumbClick(breadcrumb)" class="bread-crumb text-sm text-main cursor-pointer hover:text-primary-300" v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">> {{ breadcrumb.name }}&nbsp;</div>
                </div>
            </div>
            <div class="actions flex" v-if="(directories.is_insert && !readOnly) && getFolderPermission">
                <del-button class="mr-4" text="Add Folder" type="primary" @click="onAdd('folder')" />
                <del-button text="Add File" type="primary" @click="onAdd('file')" />
            </div>
        </div>
        <div class="mt-2 overflow-y-auto scroll-bar" style="height: calc(100% - 40px)">
            <TableLarge
                :rows="getRows" :columns="filteredColumns" 
                :filters="filtersObj" 
                @column-filter="onColumnFilter($event)" 
                @filters-clear="onClearFilter"
                @on-selected-rows-change="$emit('on-selected-rows-change', $event)"
                :config="{
                    'select-options': {
                        enabled: enableSelection,
                        selectOnCheckboxOnly: true,
                        readOnly: readOnly,
                        disableSelectInfo: true,
                    },
                }">
                <template #link="{ customData }">
                    <div class="text-main cursor-pointer hover:text-primary-300 flex items-center" @click="onDirectoryClick(customData)">
                        <template v-if="customData.type === 'directory'">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                            </svg>
                        </template>
                        <template v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                            </svg>
                        </template>
                        {{ customData.name }}
                    </div>
                </template>
                <!-- <template #source="{ customData }">
                    <template v-if="customData.size">
                        {{ formatBytes(customData.size) }}
                    </template>
                </template> -->
                <template #show_info="{ customData }">
                    <TrashRed :class="[customData.is_custom ? 'cursor-pointer' : 'custom pointer-events-none']" class="mx-auto" role="button" @click="deleteDirectory(customData)" />
                </template>
            </TableLarge>
        </div>
    </div>
</template>

<script>
import TableLarge from "@shared/dashboard-components/table-large";
import DelButton from "@shared/components/button";
import TrashRed from "@shared/assets/svg/icon-trash-red.svg";
import { DateTime } from "luxon";
import { cloneDeep } from "lodash";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "Explorer",
    props: {
        directories: {
            type: Object,
            default: () => {},
        },
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        enableSelection: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        isClient: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TableLarge,
        DelButton,
        TrashRed,
    },
    data() {
        return {
            columns: [
                {
                    label: "Name",
                    field: "name",
                    config: {
                        filter: true,
                        type: "link",
                    },
                    // tdClass: "truncate max-w-xs",
                },
                {
                    label: "Date",
                    field: "created_at",
                    config: {
                        filter: true,
                        // type: "source",
                    },
                },
                {
                    label: "Size",
                    field: "size",
                    config: {
                        filter: true,
                        type: "source",
                    },
                },
                {
                    label: "",
                    field: "actions",
                    config: {
                        filter: false,
                        type: "show_info",
                    },
                },
            ],
            filtersObj: null,
        };
    },
    computed: {
        filteredColumns() {
            return this.readOnly ? this.columns.filter((col) => col.config.type !== 'show_info') : this.columns;
        },
        getFolderPermission() {
            if(this?.isClient) {
                return true;
            } else {
                return this.checkPermission('case.actions_external')
            }
        },
        getRows() {
            let content = this.directories?.content ?? [];
            content = cloneDeep(content);
            content = content.map((directory) => {
                let obj = directory;
                if (directory.created_at) {
                    obj.created_at = DateTime.fromJSDate(new Date(directory.created_at)).toFormat("dd LLLL, yyyy");
                }
                if (directory.size) {
                    obj.size = this.formatBytes(directory.size);
                }
                return obj;
            });
            if (this.filtersObj) {
                content = content.filter((cont) => {
                    const objKey = Object.keys(this.filtersObj)[0];
                    return cont[objKey] === this.filtersObj[objKey].name;
                });
            }
            return content;
        },
    },
    methods: {
        checkPermission,
        onAdd(type) {
            this.onClearFilter();
            this.$emit(`on-add`, type);
        },
        onDirectoryClick(directory) {
            this.onClearFilter();
            this.$emit("on-directory-click", directory);
        },
        onBreadcrumbClick(breadcrumb) {
            this.onClearFilter();
            this.$emit("on-breadcrumb-click", breadcrumb);
        },
        deleteDirectory(content) {
            if (content.is_custom) {
                this.onClearFilter();
                this.$emit("on-delete-directory", content);
            }
        },
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
        },
        onColumnFilter({ columnFilters }) {
            this.filtersObj = columnFilters;
        },
        onClearFilter() {
            this.filtersObj = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.custom {
    path {
        fill: gray;
    }
}

.folderNameBreadcrumb {
    width: 70%;
}
</style>
