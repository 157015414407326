<template>
    <section class="file-manager w-full h-full">
        <tabs :tabs="tabs" :selected="selected" @on-tab-change="onTabChange($event)" v-if="showTabs" :loading="isLoading" />
        <template v-if="isLoading">
            <div class="text-center p-8">
                <loader />
            </div>
        </template>
        <template v-else>
            <div class="mt-4 flex items-center justify-between" v-allow:auth="'case.internal_folder'">
                <template v-if="directories.length">
                    <div class="files flex items-center gap-2 pb-2 overflow-x-auto scroll-bar">
                        <Folders v-for="directory in directories" :key="directory.id" :directory="directory" @on-click="onFolderClick($event, true)" :selectedFolder="topFolderSelected" />
                    </div>
                </template>
                <template v-else>
                    <empty text="No Directories Found" class="mx-auto" />
                </template>
                <template v-if="profileTab() == 3 && !readOnly">
                    <del-button v-if="checkPermission('case.actions_external')" class="ml-4 py-2" text="Generate Report" type="primary" :loader="report.loading()" @click="report.generateReport()" />
                </template>
            </div>
            <template v-if="topFolderSelected">
                <Explorer class="mt-2" style="height: calc(100% - 140px)" :readOnly="readOnly"
                    :enableSelection="enableSelection" 
                    :directories="currentContent" 
                    :breadcrumbs="getBreadcrumbs" 
                    @on-directory-click="onFolderClick($event)" 
                    @on-breadcrumb-click="onBreadcrumbClick($event)" 
                    @on-add="onAdd($event)" 
                    @on-delete-directory="onDeleteDirectory($event)"
                    :isClient="isClient"
                    @on-selected-rows-change="$emit('on-selected-rows-change', $event)" />
            </template>
        </template>
        <!-- modals  -->
        <AddFile ref="file-add" @on-add-file="onAddfile($event)" />
        <AddFolder ref="folder-add" @on-add-folder="onAddFolder($event)" />
    </section>
</template>

<script>
//components
import Tabs from "@shared/Tabs";
import DelButton from "@shared/components/button";
import Folders from "./components/Folders.vue";
import Explorer from "./components/Explorer.vue";
import Loader from "@shared/loader";
import Empty from "@shared/empty";
import VueCookies from "vue-cookies";
import { mapGetters } from "vuex";
import axios from "@/axios";
import { checkPermission } from "@shared/utils/functions";
//modals
const AddFile = () => import("./components/AddFile.vue");
const AddFolder = () => import("./components/AddFolder.vue");
//libs
// import { cloneDeep } from "lodash";

export default {
    name: "file-manager",
    data() {
        return {
            selectedFolder: null,
            breadcrumbs: [],
            topFolderSelected: null,
            folders:null,
        };
    },
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: [String, Number],
            default: "",
        },
        profileTab: {
            type: [Function],
            default: () => {},
        },
        report: {
            type: Object,
            default: () => {},
        },
        showTabs: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        directories: {
            type: Array,
            default: () => [],
        },
        directory_type: {
            type: String,
            default: "input",
        },
        enableSelection: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        isClient: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Tabs,
        DelButton,
        Folders,
        Explorer,
        AddFile,
        AddFolder,
        Loader,
        Empty,
    },
    methods: {
        checkPermission,
        onTabChange(tab) {
            if (tab.key === this.selected) return;
            this.$emit("on-tab-change", tab);
        },
        onAdd(type) {
            this.$refs[`${type}-add`].showModal();
        },
        onFolderClick(folder, isTop = false) {
            if (folder.type === "directory") {
                this.selectedFolder = folder.id;
                if (isTop) {
                    this.topFolderSelected = folder.id;
                    this.breadcrumbs = [folder];
                } else {
                    this.breadcrumbs.push(folder);
                }
            } else {
                // window.open(`/${folder.id}`)
                this.$emit("on-file-open", folder);
            }
        },
        onBreadcrumbClick(breadcrumb) {
            if (breadcrumb.id === this.selectedFolder) {
                return;
            }
            const breadcrumbIndex = this.breadcrumbs.findIndex((bread) => bread.id === breadcrumb.id);
            if (breadcrumbIndex != -1) this.breadcrumbs = this.breadcrumbs.slice(0, breadcrumbIndex + 1);
        },
        resetState() {
            this.selectedFolder = null;
            this.breadcrumbs = [];
            this.topFolderSelected = null;
        },
        onAddFolder({ name, description }) {
            this.$emit("on-add-folder", { name, description, directory_type: this.directory_type, content: this.currentContent });
        },
        onAddfile(details) {
            this.$emit("on-add-files", { ...details, content: this.currentContent, directory_type: this.directory_type });
        },
        onDeleteDirectory(data) {
            this.$emit("on-delete-directory", { ...data, directory_type: this.directory_type });
        },
        async getFolders() {
            const payload = {
                                case_id: this.$route?.query?.caseid,
                                // check_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                directory_type: "output",
                            };
                            
                            try {
                                const { data } = await axios.post(`/case-file-manager/client/get-file-data`, payload);
                                this.folders = data?.data ?? [];
                                if(this.folders?.length) {
                                let match = this.folders.find(el => el.name === this.getCaseData.client_name);
                                if(match) {
                                    this.onFolderClick(match,true)
                                }
                                if(match) {let folder = match.content.find(el => el.name.toLowerCase() == "case report")
                                if(folder) 
                                this.onFolderClick(folder)}
                    
                            }
                            } catch (error) {
                                console.log(error, "[+] error while fetching files");
                            }
        }
    },
    computed: {
         ...mapGetters(["getCaseData"]),
        getBreadcrumbs() {
            return this.breadcrumbs;
        },
        currentContent() {
            // let contents = cloneDeep(this.directories).find((directory) => directory.id === this.selectedFolder);
            let contents = null;
            this.breadcrumbs.forEach((breadcrumb) => {
                if (contents) {
                    contents = contents?.content?.find((directory) => directory.id === breadcrumb.id);
                } else {
                    contents = this.directories.find((directory) => directory.id === breadcrumb.id);
                }
            });
            return { ...contents, content: contents?.content ?? [] };
        },
    },
    mounted() {
        if(VueCookies.get("next-url")?.includes('#fileManager')){
            this.getFolders()
        }
        if(this.$route.path?.includes('outputs')){
            this.getFolders()
        }
    }
};
</script>

<style></style>
